import React from 'react'
import { useState, useRef, useEffect } from "react";
import { initializeAOS } from "../integrations/aos-init";


const SpecialltySection = () => {
  useEffect(() => {
    initializeAOS(); // Inicializar AOS cuando el componente se monte
  }, []);

  return (
    <section id="servicios" className="w-full h-full bg-white-100 py-20">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="text-center" data-aos="fade-up">
          <h2 className="text-3xl font-bold text-gray-800">Servicios</h2>
          <p className="mt-4 text-lg">Nuestras especialidades son las siguientes áreas:</p>
        </div>

        <div className="mt-12 grid gap-8 grid-cols-1 md:grid-cols-3">

          <div className="shadow-xl rounded-lg p-8" style={{ backgroundColor: "#131a29" }} data-aos="fade-left" data-aos-delay="100">
            <h3 className="text-xl text-center" style={{ color: "white", fontSize: "2rem" }}> RETAIL </h3>
            <ul className="mt-4 list-disc list-inside característicasEspecialidades">
              <li>Transacciones</li>
              <li>Sistemas de precios </li>
              <li>Sistema  de recomendaciones</li>
              <li>Seguimiento de compra</li>
              <li>Fidelización de clientes</li>
              <li>Bodegas</li>
              <li>Clientes</li>
            </ul>
          </div>

          <div className="shadow-xl rounded-lg p-8" style={{ backgroundColor: "#94c11f" }} data-aos="fade-left" data-aos-delay="200">
            <h3 className="text-xl text-center" style={{ color: "white", fontSize: "2rem" }}>Electricidad y Minería</h3>
            <ul className="mt-4 list-disc list-inside característicasEspecialidades">
              <li>Bodegas</li>
              <li>Materiales</li>
              <li>Mantenimiento</li>
              <li>Seguimiento de Vehículos</li>
              <li>Estructuras Armado Recetas (Torres de Alta Tensión)</li>
            </ul>
          </div>

          <div className="shadow-xl rounded-lg p-8" style={{ backgroundColor: "#ff0000" }} data-aos="fade-left" data-aos-delay="300">
            <h3 className="text-xl text-center" style={{ color: "white", fontSize: "2rem" }}>Telecomunicaciones</h3>
            <ul className="mt-4 list-disc list-inside característicasEspecialidades">
              <li>Trazabilidad Operativa y Comercial</li>
              <li>Suscripciones</li>
              <li>Instalaciones Georeferenciadas</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SpecialltySection